import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AgeIconImage from "../assets/other_18.png";
import Appimg from "../assets/_r174x174_webp.webp";
import Screenshot1 from "../assets/_r0x408_webp.webp";
import Screenshot2 from "../assets/_r0x408_webp2.webp";
import Screenshot3 from "../assets/_r0x408_webp3.webp";
import Shareicon from "../assets/link-fp.webp";
import Cloudicon from "../assets/cloud.webp";
import Userprofile1 from "../assets/profile1.webp";
import Userprofile2 from "../assets/profile2.webp";
import Userprofile3 from "../assets/profile3.jpg";
import Userprofile4 from "../assets/profile4.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faMobileAlt,
  faArrowRight,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  margin: 7px 24px 0 0;
`;

const AppImage = styled.img`
  width: 72px;
  border-radius: 20%;
  border-width: 0;
  box-shadow: 0 1px 2px 0 #3c40434d, 0 1px 3px 1px #3c404326;
`;

const AppInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const AppName = styled.h3`
  margin: 0;
  color: #000000;
  font-size: larger;
`;

const AppDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const AppMeta = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  position: relative;
  &:not(:last-child)::after {
    content: " ";
    width: 1px;
    height: 20px;
    background-color: #ddd;
    position: absolute;
    right: -10px;
  }
`;

const AppRatings = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: column;
`;

const Rating = styled.span`
  align-text: center;
  color: #000000;
`;

const AppDownloads = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: column;
`;

const Review = styled.span`
  font-size: 12px;
`;

const Download = styled.span`
  font-size: 12px;
`;

const Editor = styled.span`
  font-size: 12px;
`;

const AgeIcon = styled.img`
  width: 20px;
  height: 20px;
  flex-direction: column;
`;

const Age = styled.span`
  font-size: 12px;
`;

const EditorsElection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-direction: column;
`;

const AgeGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InstallButton = styled.button`
  background-color: #01875f;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.0178571429em;
  width: 100%;
  line-height: 1.25rem;
  justify-content: center;
  margin: 6px 0;
  min-height: 36px;
  min-width: 160px;
  will-change: transform, opacity;
  &:disabled {
    background-color: #01875f;
    cursor: not-allowed;
  }
`;

const Wishlist = styled.div`
  color: #01875f;
  cursor: pointer;
  letter-spacing: 0.0178571429em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 36px;
  min-width: 160px;
  will-change: transform, opacity;
  span {
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-size: smaller;
  }
  svg {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
`;

const AppAvailablity = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  min-width: 160px;
  span {
    display: flex;
    align-items: center;
    margin-left: 8px;
    font-size: 100%;
  }
`;

const AppBrand = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
`;

const Screenshots = styled.div`
  display: flex;
  margin-top: 10px;
`;

const ScreenshotImage = styled.img`
  width: 100px;
  height: auto;
  border-radius: 10px;
  margin-right: 10px;
`;

const AboutGame = styled.div`
  margin-top: 10px;
  text-align: left;
  background-color: #f9f9f9;
  padding: 10px;
  color: #333;
  font-size: 14px;
  line-height: 1.5;
`;

const DataInfo = styled.div`
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Light line border */
  background-color: #f7f7f7;
  line-height: 1.4;
`;
const Shareiconimg = styled.img`
  width: 20px;
`;
const Linkiconimg = styled.img`
  width: 20px;
`;
const RatingInfo = styled.div`
  margin-top: 10px;
`;
const Star = styled.span`
  font-size: 24px;
  color: ${(props) => (props.filled ? "#01875f" : "#e0e0e0")};
`;
const UserStar = styled.span`
  font-size: 12px;
  color: ${(props) => (props.filled ? "#01875f" : "#e0e0e0")};
`;
const StarRating = styled.div`
  display: flex;
  align-items: center;
`;
const RatingLineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const RatingLineNumber = styled.div`
  width: 20px;
  text-align: right;
  margin-right: 10px;
`;

const RatingLineBase = styled.div`
  flex-grow: 1;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
`;

const RatingLineFill = styled.div`
  width: ${(props) => props.width}%;
  height: 100%;
  border-radius: 4px;
  background-color: #01875f;
`;
const ratingPercentages = {
  5: 80,
  4: 15,
  3: 3,
  2: 1,
  1: 1,
};
const ReviewSection = styled.div`
  margin-top: 20px;
`;

const ReviewTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  display: flex;
`;
const ReviewItem = styled.p`
  margin: 30px;
  font-size: 14px;
`;
const ReviewAuthor = styled.span`
  margin-right: 5px;
  font-size: 12px;
`;
const ReviewDate = styled.span`
  font-size: 12px;
`;
const ReviewText = styled.p`
  margin: 0;
  font-size: 14px;
`;
const Morearrow = styled.span`
  cursor: pointer;
 `;
 const DataItem = styled.div`
    display: flex;
    margin-top: 10px;
    padding: 10px;
  `;
  const UserProfile = styled.img`
    width: 25px;
    height: 25px;
    border-radius: 50%;
  `;


const AppCard = ({ app, onInstallClick, isInstalled }) => {
  const [installing, setInstalling] = useState(false);

  const requestNotificationPermission = async () => {
    if ("Notification" in window) {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else {
          console.log("Notification permission denied.");
        }
      } catch (error) {
        console.error("Failed to request notification permission:", error);
      }
    } else {
      console.warn("Notification API not supported in this browser.");
    }
  };

  const handleInstallClick = async () => {
    if (installing) return;

    setInstalling(true);
    try {
      if (isInstalled) {
        window.open(app.url);
      } else {
        await onInstallClick();
        setTimeout(() => {
          setInstalling(false);
          window.open(app.url, "_blank");
        }, 7000);
      }
    } catch (error) {
      setInstalling(false);
      console.error("Operation failed:", error);
    }
  };

  useEffect(() => {
    requestNotificationPermission();
  }, []);

  return (
    <Card>
      <AppBrand>
        <AppImage src={Appimg} alt={app.name} />
        <AppInfo style={{ textAlign: "left" }}>
          <AppName>{app.name}</AppName>
          <AppName style={{ color: "#01875f" }}>994</AppName>
          <span style={{ fontSize: "smaller" }}>
            ማስታወቂያዎችን ይዟል · የውስጠ-መተግበሪያ ግዢዎች
          </span>
        </AppInfo>
      </AppBrand>

      <AppInfo>
        <AppDetails>
          <AppMeta>
            <AppRatings>
              <Rating>
                4.1{" "}
                <FontAwesomeIcon
                  icon={faStar}
                  style={{ fontSize: "smaller" }}
                />
              </Rating>
              <Review>1.4 ሺ ግምገማዎች</Review>
            </AppRatings>
            <AppDownloads>
              <div>100 ሺ+</div>
              <Download>Downloads</Download>
            </AppDownloads>
            <EditorsElection>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <rect fill="none" width="20" height="20"></rect>
                  <path d="M10.54,11.09L8.66,9.22l-1.02,1.02l2.9,2.9l5.8-5.8l-1.02-1.01L10.54,11.09z M15.8,16.24H8.2L4.41,9.66L8.2,3h7.6l3.79,6.66L15.8,16.24z M17,1H7L2,9.66l5,8.64V23l5-2l5,2v-4.69l5-8.64L17,1z"></path>
                </svg>
              </div>
              <Editor>የአርታዒዎች ምርጫ</Editor>
            </EditorsElection>
            <AgeGroup>
              <AgeIcon src={AgeIconImage} alt="Age Icon" />
              <Age>Rated for 18+</Age>
            </AgeGroup>
          </AppMeta>
        </AppDetails>
        <InstallButton onClick={handleInstallClick} disabled={installing}>
          {isInstalled ? "Open" : installing ? "Installing..." : "Install"}
        </InstallButton>
        <Wishlist>
          {isInstalled ? onInstallClick(app.url) : ""}
          <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#01875f">
              <path d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
            </svg>
            ወደ ምኞት ዝርዝር አክል
          </span>
        </Wishlist>
        <AppAvailablity>
          <span>
            <FontAwesomeIcon icon={faLaptop} />
            <FontAwesomeIcon
              icon={faMobileAlt}
              style={{ marginRight: "10px" }}
            />
            Available on all devices
          </span>
        </AppAvailablity>
        <Screenshots>
          <ScreenshotImage src={Screenshot1} alt="Screenshot 1" />
          <ScreenshotImage src={Screenshot2} alt="Screenshot 2" />
          <ScreenshotImage src={Screenshot3} alt="Screenshot 3" />
        </Screenshots>
        <AboutGame>
          <p style={{ fontWeight: "bold", fontSize: "larger" }}>ስለዚህ ጨዋታ <Morearrow> <FontAwesomeIcon icon={faArrowRight} style={{float:'right'}}/> </Morearrow> </p>
          <p>Aviator - this online-free casino.</p>
          <p>
            We specialize in exciting and fun games. Where everyone can win! Now
            you can enjoy exciting slots at home and on your mobile device.
          </p>
          <p>START playing now with HUGE welcome BONUSES!</p>
          <p style={{ fontStyle: "italic" }}>
            The games are intended for an adult audience.
            <br />
            የተዘመነው በ 23 ሜይ 2024
          </p>
          <p style={{ fontWeight: "bold", fontSize: "larger" }}>የውሂብ ደህንነት  <Morearrow><FontAwesomeIcon icon={faArrowRight} style={{float:'right'}}/></Morearrow></p>
          <p>
            In this section, developers can specify how applications collect and
            use data.
          </p>
          <DataInfo>
            <DataItem>
                <div style={{marginRight:'10px'}}>
                    <Shareiconimg src={Shareicon} />
                </div>
                <div>
                    <div> ምንም ውሂብ ለሶስተኛ ወገኖች አልተጋራም</div>
                    <div>ገንቢዎች ማጋራትን እንዴት እንደሚገልፁ ተጨማሪ ይወቁ</div>
                </div>
            </DataItem>
            <DataItem>
                    <div style={{marginRight:'10px'}}>  <Linkiconimg src={Cloudicon} /> </div>
                    <div>
                        <div> ምንም ውሂብ አልተሰበሰበም</div>
                        <div>ገንቢዎች ስብስብን እንዴት እንደሚገልፁ ተጨማሪ ይወቁ</div>
                    </div>
            </DataItem>
          
            
            <div style={{ color: "#01875f",fontWeight:'bolder' }}>ዝርዝሮችን ይመልከቱ</div>
          </DataInfo>
          <p style={{ fontWeight: "bold", fontSize: "larger" }}>
            ደረጃዎች እና ግምገማዎች
          </p>
          <RatingInfo>
            <StarRating>
              <Star filled>★</Star>
              <Star filled>★</Star>
              <Star filled>★</Star>
              <Star filled>★</Star>
              <Star>★</Star>
              <span style={{ marginLeft: "10px" }}>4.1</span>
            </StarRating>
            {[5, 4, 3, 2, 1].map((rating, index) => (
              <RatingLineWrapper key={index}>
                <RatingLineNumber>{rating}</RatingLineNumber>
                <RatingLineBase>
                  <RatingLineFill width={ratingPercentages[rating]} />
                </RatingLineBase>
              </RatingLineWrapper>
            ))}
          </RatingInfo>

          <ReviewSection>
            <ReviewItem>
              <div style={{display:'flex'}} >  
                  <UserProfile src={Userprofile1} style={{marginRight:'10px'}}/> 
                  <ReviewAuthor style={{fontSize:'14px',color:'black'}}>Tilahun Tolera</ReviewAuthor>
              </div>
              <ReviewTitle>
                <div>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                </div>
                <div style={{marginLeft:'10px'}}>
                    <ReviewDate>ሰኔ 18, 2024 ዓ.ም</ReviewDate>
                </div>
                
              </ReviewTitle>
              <ReviewText>
              Woow this absolutely awesome! It's not only super fun but also incredibly rewarding. I've already won a lot of money and the excitement just keeps growing. Highly recommend it to anyone looking for a thrilling and profitable experience!
               Geemii kana ilaaluu qabdu, baay'een jaaladhe 🤑
              </ReviewText>
            </ReviewItem>
            <ReviewItem>
              <div style={{display:'flex'}} >  
                  <UserProfile src={Userprofile2} style={{marginRight:'10px'}}/> 
                  <ReviewAuthor style={{fontSize:'14px',color:'black'}}>ብሩክ ተስፋዬ</ReviewAuthor>
              </div>
              <ReviewTitle>
                <div>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                </div>
                <div style={{marginLeft:'10px'}}>
                    <ReviewDate>የካቲት 4, 2024 ዓ.ም</ReviewDate>
                </div>
                
              </ReviewTitle>
              <ReviewText>
                  አዝናኝ፣ ፈታኝ፣ እና የሚሳተፍ። በእርግጥም በጨዋታው ላይ ለመጣር እና ለመሻሻል ሱስ ሆኖብኛል, 
                  በተለይም የማያቋርጥ ማሻሻያዎች ጋር, አዳዲስ ሽልማቶች ጋር, እና ልዩ ጨዋታዎች ጋር የተለያዩ ክስተቶች. ጨዋታው አንዳንድ ጊዜ ጥሩ ወሮታ ያስገኝልሃል. 👏👏👏
              </ReviewText>
            </ReviewItem>
            <ReviewItem>
              <div style={{display:'flex'}} >  
                  <UserProfile src={Userprofile3} style={{marginRight:'10px'}}/> 
                  <ReviewAuthor style={{fontSize:'14px',color:'black'}}>Solomon Tesfaye</ReviewAuthor>
              </div>
              <ReviewTitle>
                <div>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar >★</UserStar>
                </div>
                <div style={{marginLeft:'10px'}}>
                    <ReviewDate>21,ግንቦት 2024 ዓ.ም</ReviewDate>
                </div>
                
              </ReviewTitle>
              <ReviewText>
                ጨዋታዎቹ አዝናኝ ብቻ ሳይሆን ዳጎስ ያለ ሽልማት ስለአገኘሁበት ይህንን የጨዋታ ፕሮግራም ምርጫዬ አድርጌዋለሁ።
              </ReviewText>
            </ReviewItem>
            <ReviewItem>
              <div style={{display:'flex'}} >  
                  <UserProfile src={Userprofile4} style={{marginRight:'10px'}}/> 
                  <ReviewAuthor style={{fontSize:'14px',color:'black'}}>Biniam Tadele</ReviewAuthor>
              </div>
              <ReviewTitle>
                <div>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar filled>★</UserStar>
                  <UserStar >★</UserStar>
                </div>
                <div style={{marginLeft:'10px'}}>
                    <ReviewDate>04,ሐምሌ 2024 ዓ.ም</ReviewDate>
                </div>
                
              </ReviewTitle>
              <ReviewText>
              ዋው ደስ የሚል አፕሊኬሽን ደስ የሚል ድርጅት ነው። በሽልማቱ የረካሁበት ቤት ነው። የሚገርመው ደግሞ ለሽልማቴ ክፍያ ሳያጉላሉኝ  በፈለኩበት ሠዓት ዊዝድሮዉ ባደርግ  በፍጥነት ነው በቴሌብርም ሆነ በባንክ የገባልኝ ! ደስ ይላል አመሠግናለሁ።
              </ReviewText>
            </ReviewItem>
          </ReviewSection>
        </AboutGame>
      </AppInfo>
    </Card>
  );
};

export default AppCard;
