// src/components/AppList.js
import React from 'react';
import AppCard from './AppCard';

const AppList = ({ apps, onInstallClick, isInstalled }) => (
  <div>
    {apps.map(app => (
      <AppCard
        key={app.id}
        app={app}
        onInstallClick={() => onInstallClick(app.url)} // Pass the URL to the handler
        isInstalled={isInstalled}
      />
    ))}
  </div>
);

export default AppList;
