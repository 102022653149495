// src/App.js
import React, { useState, useEffect } from 'react';
import AppList from './components/AppList';
import './App.css';

const apps = [
  { id: 1, name: 'Aviator', image: 'link-to-image-1', url: 'https://bet994.bet/' },
  // Add more apps here
];

const App = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [installableAppUrl, setInstallableAppUrl] = useState('');

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;

    if (isStandalone) {
      setIsInstalled(true);
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    window.addEventListener('appinstalled', () => {
      setIsInstalled(true);
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
      window.removeEventListener('appinstalled', () => {});
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const handleOpenClick = (url) => {
    if (url) {
      window.location.href = url; // Redirect to the specific URL
    } else {
      console.log('No URL specified for opening the app.');
    }
  };

  return (

    <div className="App">
      <AppList apps={apps} onInstallClick={isInstalled ? handleOpenClick : handleInstallClick} isInstalled={isInstalled} />
    </div>
  );
};

export default App;
